import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is a list of projects I have worked on in the past or that I am currently
working on. This list is by far not complete. However, it should give you a good
impression of my interests, experiences and capabilities.`}</p>
    <h2>{`Software projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./turingtrader/"
        }}>{`TuringTrader: open-source quant trading platform`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./eng-db-2/"
        }}>{`Eng-DB-2: light-weight engineering database`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./pad/"
        }}>{`PAD: portable audio engine with DSP processing`}</a></li>
    </ul>
    <h2>{`FPGA projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./usb-core/"
        }}>{`TE-USB: 12Mbps USB function controller`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./8051-core/"
        }}>{`TE-51: 8051 clone`}</a></li>
    </ul>
    <h2>{`Hardware projects`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./audiowerk-8/"
        }}>{`Audiowerk 8: multi-channel sound card with PCI-interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./emi-26"
        }}>{`EMI 2|6: multi-channel audio interface with USB`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      